const fuzzyMatch = (str, pattern) => {
  // Normalize strings for matching
  const string = str.toLowerCase();
  const search = pattern.toLowerCase();

  // Early exits for efficiency
  if (search.length === 0) return { score: 1, matches: [] };
  if (search.length > string.length) return { score: 0, matches: [] };
  if (string === search)
    return {
      score: 1,
      matches: Array.from({ length: string.length }, (_, i) => i),
    };

  // Initialize dynamic programming matrix
  const matrix = Array(search.length + 1)
    .fill()
    .map(() => Array(string.length + 1).fill(0));

  // Initialize backtracking matrix
  const backtrack = Array(search.length + 1)
    .fill()
    .map(() => Array(string.length + 1).fill(null));

  // Initialize first row
  for (let j = 0; j <= string.length; j++) {
    matrix[0][j] = 1;
  }

  // Fill the matrix
  for (let i = 1; i <= search.length; i++) {
    for (let j = 1; j <= string.length; j++) {
      if (search[i - 1] === string[j - 1]) {
        // Calculate position bonus
        const positionScore = 1 - (j / string.length) * 0.7;

        // Calculate word boundary bonus
        const wordBoundaryBonus = isWordBoundary(string, j - 1) ? 0.3 : 0;

        // Calculate consecutive match bonus
        const consecutiveBonus =
          j > 1 && i > 1 && search[i - 2] === string[j - 2] ? 0.2 : 0;

        // Special character match bonus (for '/' and spaces)
        const specialCharBonus = isSpecialChar(string[j - 1]) ? 0.2 : 0;

        const matchScore =
          positionScore +
          wordBoundaryBonus +
          consecutiveBonus +
          specialCharBonus;

        const newScore = matrix[i - 1][j - 1] + matchScore;
        if (newScore > matrix[i][j - 1]) {
          matrix[i][j] = newScore;
          backtrack[i][j] = [i - 1, j - 1];
        } else {
          matrix[i][j] = matrix[i][j - 1];
          backtrack[i][j] = [i, j - 1];
        }
      } else {
        matrix[i][j] = matrix[i][j - 1];
        backtrack[i][j] = [i, j - 1];
      }
    }
  }

  // Backtrack to find matching positions
  const matches = [];
  let i = search.length;
  let j = string.length;

  while (i > 0 && j > 0) {
    const [prevI, prevJ] = backtrack[i][j];
    if (i !== prevI && j !== prevJ) {
      matches.unshift(prevJ);
    }
    [i, j] = [prevI, prevJ];
  }

  // Check for consecutive matches
  let maxConsecutive = 1;
  let currentConsecutive = 1;

  for (let k = 1; k < matches.length; k++) {
    if (matches[k] === matches[k - 1] + 1) {
      currentConsecutive++;
      maxConsecutive = Math.max(maxConsecutive, currentConsecutive);
    } else {
      currentConsecutive = 1;
    }
  }

  // Apply the consecutive match requirement only if the search pattern is 3 or more characters
  if (search.length >= 3 && maxConsecutive <= 2) {
    return { score: 0, matches: [] };
  }

  // Normalize score based on pattern length and string length
  const maxPossibleScore = search.length * (1 + 0.3 + 0.2 + 0.2); // Max bonuses
  const normalizedScore =
    matrix[search.length][string.length] / maxPossibleScore;

  return {
    score: Math.min(normalizedScore, 1),
    matches,
  };
};

// Helper functions remain the same
const isWordBoundary = (str, pos) => {
  if (pos === 0) return true;
  const curr = str[pos];
  const prev = str[pos - 1];
  return isSpecialChar(prev) || (isLowerCase(prev) && isUpperCase(curr));
};

const isSpecialChar = (char) => {
  return char === " " || char === "/" || char === "-";
};

const isUpperCase = (char) => {
  return char >= "A" && char <= "Z";
};

const isLowerCase = (char) => {
  return char >= "a" && char <= "z";
};

// New highlighting function that works with fuzzy matches
const markFuzzyMatches = (text, searchString) => {
  if (!searchString || !text) {
    return text;
  }

  const { matches, score } = fuzzyMatch(text, searchString);
  console.log(text, score);
  if (matches.length === 0) return text;

  const parts = [];
  let lastIndex = 0;

  matches.forEach((matchIndex, i) => {
    // Add unmatched text before this match
    if (matchIndex > lastIndex) {
      parts.push(text.slice(lastIndex, matchIndex));
    }

    // Add the matched character
    parts.push(<mark key={`match-${i}`}>{text[matchIndex]}</mark>);

    lastIndex = matchIndex + 1;
  });

  // Add any remaining text after the last match
  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts;
};

const searchColors = (colors, query) => {
  return colors
    .map((color) => ({
      color,
      ...fuzzyMatch(color, query),
    }))
    .filter((result) => result.score > 0)
    .sort((a, b) => b.score - a.score)
    .map((result) => result.color);
};

export { fuzzyMatch, searchColors, markFuzzyMatches };

