import { createCartEntry } from "./createCartEntry";
import axios from "../axios/directClient";
import endpoints from "../axios/endpoints";

/**
 * Creates the source payload for removing items from the source cart
 */
const createSourcePayload = (sourceVendor, sourceItems, sourceWarehouse) => {
  // Handle special case for SS cart
  if (sourceVendor === "ss") {
    const firstItem = sourceItems[0];
    return {
      distributor: sourceVendor,
      items: sourceItems.map((item) =>
        createCartEntry(
          sourceVendor,
          sourceWarehouse,
          0, // Set quantity to 0 to remove
          null,
          item,
          true,
        ),
      ),
      product_url_short: firstItem.ss_product_url_short,
    };
  }

  // Handle special case for ASC cart
  if (sourceVendor === "asc") {
    const firstItem = sourceItems[0];
    return {
      distributor: sourceVendor,
      items: sourceItems.map((item) =>
        createCartEntry(sourceVendor, sourceWarehouse, 0, null, item, true),
      ),
      asc_product_id: firstItem.asc_product_id,
    };
  }

  // Handle special case for PG cart
  if (sourceVendor === "pg") {
    const firstItem = sourceItems[0];
    return {
      distributor: sourceVendor,
      items: sourceItems.map((item) =>
        createCartEntry(sourceVendor, sourceWarehouse, 0, null, item, true),
      ),
      pg_style: firstItem.pg_style,
      pg_color_code: firstItem.pg_color_code,
    };
  }

  // Default case for other vendors
  return {
    distributor: sourceVendor,
    items: sourceItems.map((item) =>
      createCartEntry(sourceVendor, sourceWarehouse, 0, null, item, true),
    ),
  };
};

/**
 * Creates the destination payload for adding items to the destination cart
 */
const createDestinationPayload = (
  destVendor,
  destItems,
  destWarehouse,
  itemPrice,
) => {
  // Handle special case for SS cart
  if (destVendor === "ss") {
    const firstItem = destItems[0];
    return {
      distributor: destVendor,
      items: destItems.map((item) =>
        createCartEntry(
          destVendor,
          destWarehouse,
          item.quantity,
          itemPrice,
          item,
          false,
          item.itemNumber
        ),
      ),
      product_url_short: firstItem.ss_product_url_short,
    };
  }

  // Handle special case for ASC cart
  if (destVendor === "asc") {
    const firstItem = destItems[0];
    return {
      distributor: destVendor,
      items: destItems.map((item) =>
        createCartEntry(
          destVendor,
          destWarehouse,
          item.quantity,
          itemPrice,
          item,
          false,
        ),
      ),
      asc_product_id: firstItem.asc_product_id,
    };
  }

  // Handle special case for PG cart
  if (destVendor === "pg") {
    const firstItem = destItems[0];
    return {
      distributor: destVendor,
      items: destItems.map((item) =>
        createCartEntry(
          destVendor,
          destWarehouse,
          item.quantity,
          itemPrice,
          item,
          false,
        ),
      ),
      pg_style: firstItem.pg_style,
      pg_color_code: firstItem.pg_color_code,
    };
  }

  // Default case for other vendors
  return {
    distributor: destVendor,
    items: destItems.map((item) =>
      createCartEntry(
        destVendor,
        destWarehouse,
        item.quantity,
        itemPrice,
        item,
        false,
      ),
    ),
  };
};

/**
 * Switches items between vendor carts
 * @param {string} sourceVendor - Source vendor code
 * @param {Array} sourceItems - Array of items to remove from source cart
 * @param {string} sourceWarehouse - Source warehouse code
 * @param {string} destVendor - Destination vendor code
 * @param {Array} destItems - Array of items to add to destination cart
 * @param {string} destWarehouse - Destination warehouse code
 * @param {string} itemPrice - Price of items being moved
 * @param {string} userId - User's ID for authorization
 * @returns {Promise} - Promise that resolves with the switch cart response
 */
export const switchCart = async (
  sourceVendor,
  sourceItems,
  sourceWarehouse,
  destVendor,
  destItems,
  destWarehouse,
  itemPrice,
  userId,
) => {
  const source = createSourcePayload(
    sourceVendor,
    sourceItems,
    sourceWarehouse,
  );
  const destination = createDestinationPayload(
    destVendor,
    destItems,
    destWarehouse,
    itemPrice,
  );

  const headers = {
    "User-Identifier": userId,
  };

  try {
    const response = await axios.post(
      endpoints.switchCart(),
      {
        source,
        destination,
      },
      { headers },
    );
    return response.data;
  } catch (error) {
    console.error("Error switching cart:", error);
    throw error;
  }
};

