import {
  SM_CUTOFF_TIMES,
  ACC_CUTOFF_TIMES,
  lookupTimezoneOffset,
  getLocalCutoffTime,
  calculateDeliveryDate,
  ASC_CUTOFF_TIMES,
  STA_CUTOFF_TIMES,
  SLC_CUTOFF_TIMES,
} from "utilities/parseInventoryData";
import { vendorsWithoutAttachedCutoffTimes } from "constants/vendors";

export function getCutoffData(closestWhse, vendorKey, givenCutoffTime = null) {
  try {
    // Get warehouse cutoff time
    let whseCutoffTime = null;
    if (vendorKey === "sm_data") {
      whseCutoffTime = SM_CUTOFF_TIMES[closestWhse];
    } else if (vendorKey === "asc_data") {
      whseCutoffTime = ASC_CUTOFF_TIMES[closestWhse];
    } else if (vendorKey === "slc_data") {
      whseCutoffTime = SLC_CUTOFF_TIMES[closestWhse];
    } else if (givenCutoffTime) {
      whseCutoffTime = givenCutoffTime;
    }

    if (!whseCutoffTime) {
      return { cutoffIndicator: "gray", timeLeft: null };
    }

    // Parse cutoff time
    const [cutoffTime, cutoffTimeZone] = whseCutoffTime.split(" ");
    const [hours, minutes] = cutoffTime.split(":").map(Number);
    
    // Convert to 24-hour format (all times are PM)
    const cutoffHour = hours < 12 ? hours + 12 : hours;

    // Get timezone offset
    const cutoffOffset = lookupTimezoneOffset(cutoffTimeZone);
    console.log('Timezone info:', {
      cutoffOffset,
      localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });

    // Get current time (we're already in ET)
    const now = new Date();
    
    // Create today's cutoff time
    const cutoffDate = new Date(now);
    cutoffDate.setHours(cutoffHour, minutes, 0, 0);

    // If we're past today's cutoff, return red immediately
    if (now > cutoffDate) {
      return { cutoffIndicator: "red", timeLeft: null };
    }

    // Calculate time difference in minutes
    const diffInMinutes = (cutoffDate - now) / 60000;

    // Determine cutoff status for today only
    if (diffInMinutes <= 60) {
      return { cutoffIndicator: "orange", timeLeft: Math.round(diffInMinutes) };
    } else {
      return { cutoffIndicator: "green", timeLeft: Math.round(diffInMinutes / 60) };
    }

  } catch (e) {
    console.error(
      `Failed to get cutoff data for ${vendorKey} and ${closestWhse}: ${e.message}`
    );
    return { cutoffIndicator: "gray", timeLeft: null };
  }
}

export function addVendorShippingInfo(
  productData,
  vendorCode,
  est_delivery_days
) {
  if (vendorCode === "pg") {
    return addPGShippingInfo(est_delivery_days, productData);
  } else if (vendorCode === "cm") {
    return addCMDeliveryDates(productData, est_delivery_days);
  }
  return addDeliveryDates(productData, vendorCode, est_delivery_days);
}

const getCutoffTimesForVendor = (vendorCode, cutoffTimeMap) => {
  try {
    if (cutoffTimeMap) return cutoffTimeMap;

    const vendorCutoffTimes = {
      sm: SM_CUTOFF_TIMES,
      acc: ACC_CUTOFF_TIMES,
      asc: ASC_CUTOFF_TIMES,
      sta: STA_CUTOFF_TIMES,
      slc: SLC_CUTOFF_TIMES,
    };

    const cutoffTimes = vendorCutoffTimes[vendorCode];
    if (!cutoffTimes) {
      console.error("GetCutoffTimesError:", {
        error: "Missing cutoff times",
        vendorCode,
        availableVendors: Object.keys(vendorCutoffTimes),
      });
    }
    return cutoffTimes;
  } catch (error) {
    console.error("GetCutoffTimesError:", {
      error: error.message,
      vendorCode,
      stack: error.stack,
    });
    return null;
  }
};

const processWarehouseData = (warehouse, cutoffTimes, vendorCode) => {
  try {
    if (!warehouse || !Array.isArray(warehouse)) {
      console.error("ProcessWarehouseError:", {
        error: "Invalid warehouse data structure",
        warehouse,
        vendorCode,
      });
      return null;
    }

    const [warehouseName, warehouseData] = warehouse;

    if (!warehouseName) {
      console.error("ProcessWarehouseError:", {
        error: "Missing warehouse name",
        vendorCode,
        warehouse,
      });
      return null;
    }

    const trimmedWarehouseName = warehouseName.trim();
    let rawCutoffTime = cutoffTimes[trimmedWarehouseName];

    if (!rawCutoffTime && !warehouseData?.deliveryCutoffMsg) {
      console.error("ProcessWarehouseError:", {
        error: "No cutoff time found",
        warehouseName: trimmedWarehouseName,
        vendorCode,
        availableCutoffTimes: Object.keys(cutoffTimes),
      });
    }

    // Handle ACC dropshipping warehouses
    if (!rawCutoffTime && warehouseData?.deliveryCutoffMsg) {
      rawCutoffTime = warehouseData.deliveryCutoffMsg;
    }

    // const localCutoffTime = rawCutoffTime
    //   ? getLocalCutoffTime(rawCutoffTime)
    //   : "N/A";
    const localCutoffTime = rawCutoffTime;

    return {
      warehouseName: trimmedWarehouseName,
      warehouseData,
      rawCutoffTime,
      localCutoffTime,
    };
  } catch (error) {
    console.error("ProcessWarehouseError:", {
      error: error.message,
      warehouse,
      vendorCode,
      stack: error.stack,
    });
    return null;
  }
};

const createWarehouseEntry = (warehouseInfo, est_delivery_days) => {
  try {
    if (!warehouseInfo) {
      console.error("CreateWarehouseEntryError:", {
        error: "Missing warehouse info",
        warehouseInfo,
      });
      return null;
    }

    const { warehouseName, warehouseData, rawCutoffTime, localCutoffTime } =
      warehouseInfo;

    if (!rawCutoffTime || localCutoffTime === "N/A") {
      return [
        warehouseName,
        {
          ...warehouseData,
          estDeliveryDate: "3-7 Days",
          deliveryCutoffTime: "N/A",
        },
      ];
    }

    const estDate = calculateDeliveryDate(
      warehouseName,
      est_delivery_days,
      rawCutoffTime
    );
    if (!estDate) {
      console.error("CreateWarehouseEntryError:", {
        error: "Failed to calculate delivery date",
        warehouseName,
        est_delivery_days,
        rawCutoffTime,
      });
    }

    return [
      warehouseName,
      {
        ...warehouseData,
        estDeliveryDate: estDate || "3-7 Days",
        deliveryCutoffTime: localCutoffTime,
      },
    ];
  } catch (error) {
    console.error("CreateWarehouseEntryError:", {
      error: error.message,
      warehouseInfo,
      est_delivery_days,
      stack: error.stack,
    });
    return null;
  }
};

const addVendorSpecificData = (productData, vendorCode) => {
  try {
    if (!vendorsWithoutAttachedCutoffTimes.has(vendorCode)) {
      return productData;
    }

    if (!productData?.inventory_data?.[0]?.[0]) {
      console.error("VendorSpecificDataError:", {
        error: "Missing inventory data",
        vendorCode,
        productDataStructure: {
          hasInventoryData: !!productData?.inventory_data,
          inventoryDataLength: productData?.inventory_data?.length,
        },
      });
      return productData;
    }

    const cutoff_whse = productData.inventory_data[0][0].trim();
    const vendorDataKey = `${vendorCode}_data`;

    const cutoffResult = getCutoffData(cutoff_whse, vendorDataKey);
    if (!cutoffResult) {
      console.error("VendorSpecificDataError:", {
        error: "Failed to get cutoff data",
        vendorCode,
        cutoff_whse,
        vendorDataKey,
      });
    }

    const { cutoffIndicator, timeLeft } = cutoffResult || {
      cutoffIndicator: "gray",
      timeLeft: null,
    };

    return {
      ...productData,
      cutoff_whse,
      cutoff_indicator: cutoffIndicator,
      remaining_order_time: timeLeft,
    };
  } catch (error) {
    console.error("VendorSpecificDataError:", {
      error: error.message,
      vendorCode,
      productDataKeys: Object.keys(productData || {}),
      stack: error.stack,
    });
    return productData;
  }
};

export function addDeliveryDates(
  productData,
  vendorCode,
  est_delivery_days,
  cutoffTimeMap = null
) {
  try {
    // Initial validation
    if (!est_delivery_days || !Object.keys(productData).length) {
      return productData;
    }

    // Get cutoff times
    const cutoffTimes = getCutoffTimesForVendor(vendorCode, cutoffTimeMap);
    if (!cutoffTimes) {
      return productData;
    }

    // Process inventory data
    const new_inventory_data = [];
    for (const warehouse of productData?.inventory_data || []) {
      const warehouseInfo = processWarehouseData(
        warehouse,
        cutoffTimes,
        vendorCode
      );
      if (!warehouseInfo) continue;

      const warehouseEntry = createWarehouseEntry(
        warehouseInfo,
        est_delivery_days
      );
      new_inventory_data.push(warehouseEntry);
    }

    // Add vendor specific data and return
    let newProductData = { ...productData, inventory_data: new_inventory_data };
    return addVendorSpecificData(newProductData, vendorCode);
  } catch (error) {
    console.error("Error in addDeliveryDates:", {
      vendorCode,
      error: error.message,
      productDataKeys: Object.keys(productData || {}),
    });
    return productData;
  }
}

export function addPGShippingInfo(pgEstDeliveryDays, respData) {
  try {
    if (!pgEstDeliveryDays || Object.keys(respData).length === 0) {
      return respData;
    }
    let newProductData = { ...respData };

    // need to sort warehouses based off distance
    // and get corresponding cutoff times (for cutoff time indicator and inventory table)
    let cutoffWarehouse = null;
    const sortedWarehouses = [...Object.entries(pgEstDeliveryDays)].sort(
      (a, b) => a[1] - b[1]
    );
    const whseCutoffTimeMap = newProductData?.inventory_data?.reduce(
      (accumulator, whse) => {
        const warehouseName = whse[0];
        if (whse[1]["deliveryCutoffTime"].includes("Cutoff")) {
          const cutoffTimeDetails = whse[1]["deliveryCutoffTime"].split(" "); // e.g. Cutoff 3:00 PM PT
          const warehouseCutoffTime =
            cutoffTimeDetails[1] + " " + cutoffTimeDetails[3]; // to get e.g. 3:00 PT
          accumulator[warehouseName] = warehouseCutoffTime;
          return accumulator;
        }
        // cases for Ships Next Day
        accumulator[warehouseName] = whse[1]["deliveryCutoffTime"];
        return accumulator;
      },
      {}
    );

    // getting data to display cutoff time indicator and tooltip
    cutoffWarehouse = sortedWarehouses.length > 0 ? sortedWarehouses[0] : null;
    if (cutoffWarehouse) {
      const targetWhseCutoffEntry = Object.entries(whseCutoffTimeMap).find(
        (whseEntry) => whseEntry[0] === cutoffWarehouse[0]
      );
      const givenCutoffTime = targetWhseCutoffEntry
        ? targetWhseCutoffEntry[1]
        : null;
      const { cutoffIndicator, timeLeft } = getCutoffData(
        cutoffWarehouse[0],
        "pg_data",
        givenCutoffTime
      );
      newProductData.cutoff_whse = cutoffWarehouse[0];
      newProductData.cutoff_indicator = cutoffIndicator;
      newProductData.remaining_order_time = timeLeft;
    } else {
      newProductData.cutoff_indicator = "gray";
    }

    // add estimated delivery dates to render in inventory table
    newProductData = addDeliveryDates(
      newProductData,
      "pg",
      pgEstDeliveryDays,
      whseCutoffTimeMap
    );

    return newProductData;
  } catch (error) {
    console.error(`Error adding pg shipping info: ${error.message}`);
  }
}

export function addCMDeliveryDates(productData, cmEstDeliveryDays) {
  let newProductData = { ...productData };
  const new_inventory_data = [];
  if (!productData?.inventory_data) {
    return newProductData;
  }
  for (const warehouse of productData?.inventory_data) {
    const [warehouseName, warehouseData] = warehouse;

    if (!warehouseName) {
      continue;
    }
    if (!warehouseName.includes("Indian Trail")) {
      new_inventory_data.push([
        warehouseName,
        {
          ...warehouseData,
          estDeliveryDate: "3-7 days",
          deliveryCutoffTime: warehouseData.deliveryCutoffMsg,
        },
      ]);
      continue;
    }

    const rawCutoffTime = warehouseData.deliveryCutoffTime;
    // const localCutoffTime = getLocalCutoffTime(rawCutoffTime.trim());
    const localCutoffTime = rawCutoffTime;
    new_inventory_data.push([
      warehouseName,
      {
        ...warehouseData,
        estDeliveryDate: calculateDeliveryDate(
          warehouseName.replace("NC-Main Warehouse", " NC"),
          cmEstDeliveryDays,
          localCutoffTime
        ),
        deliveryCutoffTime: localCutoffTime,
      },
    ]);
  }
  newProductData.inventory_data = new_inventory_data;
  return newProductData;
}
