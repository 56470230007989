import React, { useEffect, useState } from "react";

import { Box, Alert, Button, Stack } from "@mui/material";
import ColorSelector from "./ColorSelector";
import InventoryContent from "./InventoryContent";
import ProductPageSampleAd from "./ProductPageSampleAd";
import ProductPageSimilarAd from "./ProductPageSimilarAd";
import DataToggles from "dashboard/DataToggles";
import DirectLogo from "items/DirectLogo";

import { useDataContext, useUserStateContext } from "App";
import { useProductContext } from "./ProductPage";
import { useDispatch, useSelector } from "react-redux";
import { selectStyle } from "features/styleSlice";
import { LOADING_STATES } from "features/productsSlice";
import {
  getConnectedVendors,
  getExistingProductKeys,
  createSocketPayload,
  sendPayload,
} from "features/useSocketPayloadSender";

import vendors from "constants/vendors";
import { useNavigate } from "react-router-dom";
import { fuzzyMatch } from "utilities/fuzzyMatch";

const ConnectVendorsInfoAlert = ({
  unconnectedVendorCodes,
  noRelevantVendorsConnected,
}) => {
  const navigate = useNavigate();
  if (!noRelevantVendorsConnected) {
    return null;
  }
  const generateVendorsConnectPrompt = () => {
    const vendorNames = unconnectedVendorCodes
      ?.map((code) => vendors[code + "_data"]?.name)
      .filter(Boolean); // Remove any undefined or null values

    if (vendorNames?.length === 0) {
      return (
        <span>
          Connect to vendors to see your pricing and inventory for this item!
        </span>
      );
    }

    if (vendorNames?.length === 1) {
      return (
        <span className="text-wolfGrey">
          Connect to <strong className="text-dgiBlack">{vendorNames[0]}</strong>{" "}
          to see your pricing and inventory for this item!
        </span>
      );
    }

    const lastVendor = vendorNames?.pop();
    const boldVendors = vendorNames?.map((name, index) => (
      <React.Fragment key={index}>
        <strong>{name}</strong>
        {index < vendorNames?.length - 1 ? ", " : ""}
      </React.Fragment>
    ));

    return (
      <span className="text-[15px]">
        Connect to {boldVendors}
        {vendorNames?.length > 0 ? ", or " : ""}
        <strong>{lastVendor}</strong> to see your pricing and live inventory for
        this item!
      </span>
    );
  };

  const navigateToConnections = () => {
    if (unconnectedVendorCodes?.length === 1) {
      const vendorCode = unconnectedVendorCodes[0];
      navigate(`/integrations?vendor=${vendorCode}`);
      return;
    } else if (unconnectedVendorCodes?.length > 1) {
      navigate("/integrations");
      return;
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignContent="center"
      className="bg-babyBlue py-[10px] rounded-soft mb-[1rem] cursor-pointer"
      onClick={() => navigateToConnections()}
    >
      <Alert
        severity="info"
        className={`
          max-w-[500px]
          min-h-[55px] h-fit
          flex items-start bg-babyBlue`}
        sx={{
          "& svg": {
            fill: "rgb(2 129 192)", // lighterblueblue
            width: "24px",
            height: "24px",
          },
        }}
      >
        {generateVendorsConnectPrompt()}
      </Alert>
      <Box className="w-full flex justify-center">
        <Button
          color="inherit"
          size="small"
          onClick={() => navigateToConnections()}
          className={`bg-lighterblueblue hover:bg-denim 
            text-grayscaleWhite normal-case text-base
            w-fit py-[7px] px-[8px]`}
        >
          Connect
        </Button>
      </Box>
    </Stack>
  );
};

const RenderInventoryContent = ({
  vendorDataCodes,
  areDataKeysOrdered,
  colorObj,
  loadingTableData,
  setLoadingTableData,
  selectedVendorCode,
  setSelectedVendorCode,
  vendorProducts,
  noRelevantVendorsConnected,
  hasColors,
}) => {
  if (noRelevantVendorsConnected) {
    return null;
  }

  return (
    <InventoryContent
      vendorDataCodes={vendorDataCodes}
      areDataKeysOrdered={areDataKeysOrdered}
      colorObj={colorObj}
      loadingTableData={loadingTableData}
      setLoadingTableData={setLoadingTableData}
      selectedVendorCode={selectedVendorCode}
      setSelectedVendorCode={setSelectedVendorCode}
      vendorProducts={vendorProducts}
      hasColors={hasColors}
    />
  );
};

const RenderDirectLogo = ({ isDirectProduct }) => {
  if (!isDirectProduct) {
    return null;
  }

  return (
    <Box className="mt-[10px] ml-[8px]">
      <DirectLogo displayLogo={isDirectProduct} onProductPage={true} />
    </Box>
  );
};

const ColorInventoryGroup = ({
  selectedVendorCode,
  setSelectedVendorCode,
  displayVendors,
  displaySuggestedVendors,
  vendorProducts,
  dgiStyle,
  colorQuery,
  sendJsonMessage,
  unconnectedVendorCodes,
  hasColors,
  isDirectProduct,
  isLGOrAbove,
}) => {
  const { colorObj, colorNames, brand } = useProductContext();
  const { userCreds } = useUserStateContext();
  const [loadingTableData, setLoadingTableData] = useState(false);
  const dispatch = useDispatch();
  const styleData = useSelector((state) => selectStyle(state, dgiStyle));

  const showOnlySyncedVendors = useSelector(
    (state) => state.user?.user?.show_only_synced_vendors
  );
  const [colorInSearchBox, setColorInSearchBox] = useState("");
  const { connectedVendorCodes } = useDataContext();

  const productKeys = [{ dgi_style_sku: dgiStyle, master_color: colorQuery }];
  const connectedVendors = getConnectedVendors(connectedVendorCodes);

  useEffect(() => {
    // fetching all relevant vendors' inventory data
    (async () => {
      if (!hasColors || (hasColors && colorObj)) {
        const getMasterColor = (styleData) => {
          return styleData?.colors.find((color) => color?.name === colorQuery);
        };
        const existingProductKeys = getExistingProductKeys(
          dispatch,
          productKeys,
          connectedVendors
        );
        const userId = userCreds?.cognitoID;
        const payload = createSocketPayload(
          dispatch,
          userId,
          existingProductKeys,
          [styleData],
          connectedVendors,
          getMasterColor
        );
        sendPayload(sendJsonMessage, payload);
      }
    })();
  }, [userCreds, colorObj, hasColors, dgiStyle]);

  // recreating a field we use in Product that is then passed to InventoryDetails
  let areDataKeysOrdered = false;
  if (displayVendors.length > 0) {
    function checkAllDataFetched() {
      const existingDataKeys = Object.keys(vendorProducts);
      for (const vendor of displayVendors) {
        const vendorDataKey = vendor[0];
        const vendorProductKey = dgiStyle + vendorDataKey + colorObj?.name;
        if (
          !existingDataKeys.includes(vendorProductKey) ||
          (vendorProductKey[vendorDataKey]?.loading !==
            LOADING_STATES.SUCCESS_STATUS &&
            vendorProductKey[vendorDataKey]?.loading !==
              LOADING_STATES.FAILURE_STATUS)
        ) {
          return false;
        }
      }
      return true;
    }
    areDataKeysOrdered = checkAllDataFetched();
  }

  // recreating a field we use in Product that is then passed to InventoryDetails
  let vendorDataCodes = [];
  if (displayVendors.length > 0) {
    const suggestedAndConnectedVendors = displayVendors.concat(
      displaySuggestedVendors
    );
    const allRelevantVendors = showOnlySyncedVendors
      ? displayVendors
      : suggestedAndConnectedVendors;
    vendorDataCodes = allRelevantVendors.map((v) => v["code"]);
  }

  const adStyleNumber = styleData?.ad_style;
  // This check is very important as we do not want to count ad impressions
  // when there is no ad content to load.
  const shouldDisplaySimilarAd = Boolean(adStyleNumber);
  const showColorSelector = Boolean(colorNames.length);

  // Sort colors by fuzzy match score and filter out non-matches
  const filteredColorNames = colorNames
    .filter(colorName => !colorInSearchBox || fuzzyMatch(colorName, colorInSearchBox).score > 0)
    .sort((a, b) => {
      const scoreA = fuzzyMatch(a, colorInSearchBox);
      const scoreB = fuzzyMatch(b, colorInSearchBox);
      return scoreB.score - scoreA.score;
    });

  const noRelevantVendorsConnected =
    displayVendors.length === 0 || vendorProducts.length === 0;

  return (
    <Box width="100%" className="flex flex-col items-center gap-[2rem]">
      <Box className="w-full flex items-start justify-between">
        {isLGOrAbove && (<RenderDirectLogo isDirectProduct={isDirectProduct} />)}
        {noRelevantVendorsConnected ? (
          <Box height="90px" />
        ) : (
          <Box className="hidden lg:flex w-full justify-end">
            <DataToggles onProductPage={true} />
          </Box>
        )}
        <ConnectVendorsInfoAlert
          unconnectedVendorCodes={unconnectedVendorCodes}
          noRelevantVendorsConnected={noRelevantVendorsConnected}
        />
      </Box>
      {brand === "Allmade" && (
        <Box width="100%" paddingLeft="2rem">
          <ProductPageSampleAd
            originalStyleData={styleData}
            colorQuery={colorQuery}
          />
        </Box>
      )}
      {shouldDisplaySimilarAd && (
        <Box className="flex justify-center w-full">
          {/* <ProductPageSimilarAd
            originalStyleData={styleData}
            origialProductColor={colorQuery}
            sendJsonMessage={sendJsonMessage}
          /> */}
        </Box>
      )}
      {showColorSelector && (
        <Box display="flex" flexDirection="column" width="100%" gap="0rem">
          <ColorSelector
            filteredColorNames={filteredColorNames}
            colorInSearchBox={colorInSearchBox}
            setColorInSearchBox={setColorInSearchBox}
            noRelevantVendorsConnected={noRelevantVendorsConnected}
          />
        </Box>
      )}
      <RenderInventoryContent
        vendorDataCodes={vendorDataCodes}
        areDataKeysOrdered={areDataKeysOrdered}
        colorObj={colorObj}
        loadingTableData={loadingTableData}
        setLoadingTableData={setLoadingTableData}
        selectedVendorCode={selectedVendorCode}
        setSelectedVendorCode={setSelectedVendorCode}
        vendorProducts={vendorProducts}
        noRelevantVendorsConnected={noRelevantVendorsConnected}
        hasColors={hasColors}
      />
    </Box>
  );
};

export default ColorInventoryGroup;
