import { createApi } from "@reduxjs/toolkit/query/react";
import { Auth } from "aws-amplify";

const BASE_API_URLS = {
  development: "https://fcba17gw9k.execute-api.us-east-1.amazonaws.com",
  staging: "https://fcba17gw9k.execute-api.us-east-1.amazonaws.com",
  production: "https://byyhh7s57b.execute-api.us-east-1.amazonaws.com",
};

const BASE_API_URL =
  process.env.REACT_APP_LOCAL_DEV === "true"
    ? "http://127.0.0.1:8000"
    : BASE_API_URLS[process.env.NODE_ENV] || BASE_API_URLS.production;

const customBaseQuery = async (args, api, extraOptions) => {
  const { baseUrl, url, method, headers = {}, ...rest } = args;
  try {
    const session = await Auth.currentSession();
    const jwtToken = session.accessToken.jwtToken;

    const response = await fetch(`${baseUrl}${url}`, {
      method,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
        ...headers,
      },
      ...rest,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const data = await response.json();
      return { data };
    }

    return { data: null };
  } catch (error) {
    console.error("API Error:", error);
    return {
      error: {
        status: error.response?.status || 500,
        data: error.response?.data || error.message,
      },
    };
  }
};

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getVendorByCode: builder.query({
      query: (vendorCode) => ({
        baseUrl: BASE_API_URL,
        url: `/vendors/${vendorCode}`,
        method: "GET",
      }),
      providesTags: (result, error, vendorCode) => [
        { type: "Vendor", id: vendorCode },
      ],
    }),
    postVendorReview: builder.mutation({
      query: ({ vendorCode, reviewData, userId }) => ({
        baseUrl: BASE_API_URL,
        url: `/vendors/${vendorCode}/review`,
        method: "POST",
        body: JSON.stringify(reviewData),
        headers: {
          "Content-Type": "application/json",
          "user-identifier": userId,
        },
      }),
      invalidatesTags: (result, error, { vendorCode }) => [
        { type: "Vendor", id: vendorCode },
      ],
    }),
    updateUserById: builder.mutation({
      query: ({ userId, userData }) => ({
        baseUrl: BASE_API_URL,
        url: `/users/${userId}`,
        method: "PUT",
        body: JSON.stringify(userData),
      }),
    }),
    getUserById: builder.query({
      query: (userId) => ({
        baseUrl: BASE_API_URL,
        url: `/users/${userId}`,
        method: "GET",
      }),
    }),
    getAccountDetailsById: builder.query({
      query: (userId) => {
        // Skip the query if userId is undefined
        if (!userId) {
          return { skip: true };
        }
    
        return {
          baseUrl: BASE_API_URL,
          url: `/account-details/${userId}`,
          method: "GET",
        };
      },
    }),
    getDirectVendors: builder.query({
      query: () => ({
        baseUrl: BASE_API_URL,
        url: `/vendors/type/direct`,
        method: "GET",
      }),
      providesTags: ["DirectVendors"],
    }),
    getOrders: builder.query({
      query: ({ userId, batchNumber = 1, itemsPerPage = 300 }) => {
        // Construct query string with parameters
        const queryParams = new URLSearchParams({
          page: batchNumber.toString(),
          items_per_page: itemsPerPage.toString(),
        }).toString();
        return {
          baseUrl: BASE_API_URL,
          url: `/orders/${userId}?${queryParams}`,
          method: "GET",
          headers: {
            "User-Identifier": userId,
          },
          params: {
            page: batchNumber,
            items_per_page: itemsPerPage,
          },
        };
      },
      // Merge results from multiple batches
      serializeQueryArgs: ({ queryArgs }) => {
        return { userId: queryArgs.userId };
      },
      merge: (currentCache, newItems, { arg }) => {
        if (!currentCache) {
          currentCache = {
            data: [],
            hasMore: false,
            totalOrders: 0,
          };
        }
        const itemsArray = Array.isArray(newItems.data) ? newItems.data : [];
        currentCache.data = currentCache.data.concat(itemsArray);

        // Update metadata
        currentCache.hasMore = itemsArray.length === 300; // Full batch indicates more items
        currentCache.totalOrders = Math.max(
          currentCache.totalOrders,
          (arg.batchNumber - 1) * 300 + itemsArray.length,
        );

        return currentCache;
      },

      // Transform the response to include pagination info
      transformResponse: (response, meta, arg) => {
        return {
          data: response,
          hasMore: response.length === 300,
          totalOrders: response.length,
        };
      },

      // Prevent duplicate batch fetches
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.batchNumber !== previousArg?.batchNumber;
      },
    }),
  }),
})

export const {
  useGetVendorByCodeQuery,
  usePostVendorReviewMutation,
  useGetUserByIdQuery,
  useUpdateUserByIdMutation,
  useGetAccountDetailsByIdQuery,
  useGetDirectVendorsQuery,
  useGetOrdersQuery,
} = baseApi;
