import React from "react";
import styles from "../styles.module.scss";
import VendorLogo from "custom/img/VendorLogo";
import { formatPrice } from "utilities/formatPrice";

function VendorFilter({
  vendorCode,
  cartTotalPrice,
  isSelected,
  unitPrice,
  onSelect,
}) {
  return (
    <div
      className={`!justify-start ${styles.cartFilterWrapper}`}
      data-selected={isSelected}
      onClick={() => onSelect(vendorCode)}
    >
      <div
        className={`!w-[99%] ${styles.cartFilter}`}
        data-selected={isSelected}
      >
        <div className="flex flex-col items-start gap-[4px]">
          <div className="flex justify-between w-[225px]">
            <VendorLogo vendorCode={vendorCode} />
            <div className="itemUnitPrice">
              {Number(unitPrice) ? formatPrice(unitPrice) : unitPrice}
            </div>
          </div>
          <div className="vendorCartTotal text-xs text-typeGrey">
            {`Cart Total: ${formatPrice(cartTotalPrice || 0)}`}
          </div>
        </div>
      </div>
    </div>
  );
}

function VendorFilterBar({
  sortedValidCarts,
  selectedVendorCode,
  getVendorUnitPrice,
  onVendorSelect,
}) {
  return (
    <div className="flex flex-col space-y-2 w-1/3">
      {sortedValidCarts?.map(({ code, totalPrice }) => (
        <VendorFilter
          key={code}
          vendorCode={code}
          cartTotalPrice={totalPrice}
          isSelected={selectedVendorCode === code}
          unitPrice={getVendorUnitPrice(code)}
          onSelect={onVendorSelect}
        />
      ))}
    </div>
  );
}

export default VendorFilterBar;
