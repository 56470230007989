import React from "react";
import { Box, Typography, Grid, Skeleton, Button } from "@mui/material";
import { RenderSupplierRating } from "integrations/SupplierRating";
import { useNavigate } from "react-router-dom";

const VendorCard = ({ vendor }) => {
  const navigate = useNavigate();

  // Display skeleton if vendor is null
  if (!vendor) {
    return (
      <Box
        p={2}
        borderRadius="8px"
        maxWidth="380px"
        mx="auto"
        sx={{
          boxShadow: 1,
          "&:hover": {
            boxShadow: 5,
          },
        }}
      >
        <Skeleton variant="text" width="60%" height={32} />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={20}
          sx={{ mt: 1, mb: 1 }}
        />
        {[...Array(5)].map((_, index) => (
          <Skeleton key={index} variant="text" width="80%" height={24} />
        ))}
      </Box>
    );
  }

  // Construct the logo URL using the vendor's code
  const logoUrl =`https://d34c0c1in94n75.cloudfront.net/${vendor.vendorCode}_logo.png`;

  return (
    <Box
      p={2}
      pb={1}
      borderRadius="8px"
      maxWidth="380px"
      minWidth="320px"
      mx="auto"
      onClick={() => !vendor.comingSoon && navigate(`/direct/vendors/${vendor.vendorCode}`)}
      sx={{
        boxShadow: 1,
        transition: "box-shadow 0.2s ease-in-out",
        cursor: vendor.comingSoon ? "default" : "pointer",
        opacity: vendor.comingSoon ? 0.8 : 1,
        "&:hover": {
          boxShadow: vendor.comingSoon ? 1 : 5,
        },
      }}
    >
      {/* Header section with vendor name, rating, and logo */}
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h6">
            {vendor.vendorName || "Vendor Name"}
          </Typography>
          {vendor.comingSoon ? (
            <Typography
              variant="subtitle1"
              sx={{
                color: "rgba(0, 88, 141, 1)",
                fontWeight: "bold",
                mt: 1,
                height: "24px",
              }}
            >
              Coming Soon
            </Typography>
          ) : (
            <RenderSupplierRating vendorInfo={vendor} showReviewCount />
          )}
        </Box>
        <Box 
          width="100px" 
          height="70px" 
          display="flex" 
          alignItems="center"
        >
          <img
            src={logoUrl}
            alt={`${vendor.vendorName} logo`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              opacity: vendor.comingSoon ? 0.7 : 1,
            }}
          />
        </Box>
      </Box>

      {/* Display Vendor Information */}
      <Box mt={2}>
        {[
          {
            label: "Free Shipping At",
            value:
              vendor.freeShippingMinimum === null ||
              vendor.freeShippingMinimum === undefined ||
              vendor.freeShippingMinimum === 0 ||
              vendor.freeShippingMinimum === "0" ? (
                <Typography variant="body1" color="textSecondary">
                  N/A
                </Typography>
              ) : (
                `$${vendor.freeShippingMinimum}`
              ),
          },
          {
            label: "Standard Shipping",
            value:
              vendor.standardShippingPrice === 0 ||
              vendor.standardShippingPrice === "0"
                ? "FREE"
                : `$${Number(vendor.standardShippingPrice).toFixed(2)}`,
          },
          {
            label: "Location",
            value: vendor.address
              ? `${vendor.address.city}, ${vendor.address.state}`
              : "N/A",
          },
          { label: "Unique Products", value: vendor.uniqueProducts || "N/A" },
          { label: vendor.comingSoon ? "Joining DGI" : "Joined DGI", value: vendor.joinedDGI || "N/A" },
          {
            label: "Categories",
            value: Array.isArray(vendor.categories)
              ? vendor.categories.join(", ")
              : vendor.categories || "N/A",
          },
        ].map((field, index) => (
          <Grid container key={index} mb={0.5}>
            <Grid item xs={6}>
              <Typography variant="body1" color="textSecondary">
                {field.label}:
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              {typeof field.value === "string" ? (
                <Typography variant="body1">{field.value}</Typography>
              ) : (
                field.value
              )}
            </Grid>
          </Grid>
        ))}
      </Box>
      {/* View Details Button */}
      {!vendor.comingSoon && (
        <Box mt={2} textAlign="center">
          <Button
            variant="text"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/direct/vendors/${vendor.vendorCode}`);
            }}
            sx={{
              color: "rgba(0, 88, 141, 1)",
              transition: "background-color 0.2s ease-in-out",
              fontWeight: "600",
              width: "100%",
              "&:hover": {
                backgroundColor: "rgb(214 241 249)",
              },
            }}
          >
            View Details
          </Button>
        </Box>
      )}
      {vendor.comingSoon && <Box mt={2} height="36px" />}
    </Box>
  );
};

export default VendorCard;
