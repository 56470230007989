import React, { useRef, useState } from "react";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SimilarProductCard } from "items/SimilarSlideContent";
import { useSelector } from "react-redux";
import { getConnectedVendors } from "features/useSocketPayloadSender";
import { useDataContext } from "App";
import { useDispatch } from "react-redux";

export default function TopStyles() {
  const topStyles = useSelector((state) => state.user?.user?.topStyles);
  const firstName = useSelector((state) => state.user?.user?.firstName);
  const { connectedVendorCodes } = useDataContext();
  const vendorCodes = getConnectedVendors(connectedVendorCodes);
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);

  const scroll = (scrollOffset) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    }
  };

  // Default dgiStyles array to use if topStyles doesn't exist
  const defaultDgiStyles = [
    { dgiStyle: '1000652' },
    { dgiStyle: '1000644' },
    { dgiStyle: '1009857' },
    { dgiStyle: '1000453' },
    { dgiStyle: '1000856' },
    { dgiStyle: '1000932' },
    { dgiStyle: '1009728' },
    { dgiStyle: '1009819' },
    { dgiStyle: '1000647' },
    { dgiStyle: '1000661' },
  ];

  // Use topStyles if it exists, otherwise fall back to the default array
  const stylesToDisplay = topStyles?.length > 0 ? topStyles.slice(0, 10) : defaultDgiStyles;

  return (
    <Stack
      padding="1rem"
      paddingTop="1rem"
      marginTop="0rem"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        alignItems: "center",
        height: "390px",
        display: "flex",
        justifyContent: "center",
        // backgroundColor: "#f9f9f9",
        width: "100%",
        position: "relative",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "rgba(65, 75, 86, 1)",
          textAlign: "center",
          marginBottom: "0.8rem",
        }}
      >
        {firstName ? `${firstName}'s Favorites` : 'Your Favorites'}
      </Typography>
      <Box
        ref={scrollContainerRef}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          overflowX: "hidden",
          width: "100%",
          flexWrap: "nowrap",
          paddingBottom: "1rem",
          scrollBehavior: "smooth",
        }}
      >
        {stylesToDisplay.map((s) => (
          <Box
            key={s.dgiStyle}
            sx={{
              flexShrink: 0,
              width: "200px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              textAlign: "left",
            }}
          >
            <SimilarProductCard 
              dgiStyleSku={s.dgiStyle}
              originalDGIStyleSKU={s.dgiStyle}
              vendorCodes={vendorCodes}
              dispatch={dispatch}  
            />
          </Box>
        ))}
      </Box>
      <IconButton
        onClick={() => scroll(-400)}
        sx={{
          position: "absolute",
          left: 10,
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "rgb(103, 111, 120)",
          color: "white",
          opacity: isHovering ? 1 : 0,
          transition: "opacity 0.3s",
          '&:hover': {
            backgroundColor: "rgb(83, 91, 100)",
          },
        }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <IconButton
        onClick={() => scroll(400)}
        sx={{
          position: "absolute",
          right: 10,
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "rgb(103, 111, 120)",
          color: "white",
          opacity: isHovering ? 1 : 0,
          transition: "opacity 0.3s",
          '&:hover': {
            backgroundColor: "rgb(83, 91, 100)",
          },
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Stack>
  );
}
