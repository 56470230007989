import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Backdrop,
} from "@mui/material";
import { Shuffle } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { createSelectVendorProducts } from "features/productsSlice";
import { useUserStateContext } from "App";
import { LOADING_STATES } from "features/productsSlice";
import { fetchCartSuccess } from "features/cartsSlice";
import { switchCart } from "utilities/switchCart";
import { fetchPrice } from "features/useSocketPayloadSender";
import { useWebsocketContext } from "authsignin/AuthedPage";
import { selectStyle } from "features/styleSlice";
import { processProductData } from "pages/product/ProductPage";
import VendorOptionsWrapper from "./VendorOptions";
import lilProductClient from "../../../axios/lilProductClient";
import endpoints from "../../../axios/endpoints";
import { fetchStyleSuccess } from "features/styleSlice";

function SwitchCartDialog({
  open,
  handleClose,
  item,
  sourceVendorCode,
  styleData,
}) {
  const { userCreds } = useUserStateContext();
  const dispatch = useDispatch();
  const { sendJsonMessage } = useWebsocketContext();

  const connectedVendors = useSelector(
    (state) => Object.keys(state?.user?.user?.Distributors) || []
  );

  const selectItemInventory = createSelectVendorProducts(
    item?.dgi_style_sku,
    connectedVendors,
    item?.master_color
  );
  const itemInventory = useSelector(selectItemInventory);

  useEffect(() => {
    const allValuesUndefined = Object.values(itemInventory || {}).every(
      (value) => value === undefined
    );
    const dataStillLoading = Object.values(itemInventory).some(
      (value) => value?.loading === LOADING_STATES.LOADING_STATUS
    );

    if (allValuesUndefined || !dataStillLoading) {
      fetchPrice(
        dispatch,
        userCreds?.cognitoID,
        connectedVendors,
        styleData,
        item?.master_color,
        sendJsonMessage
      );
    }
  }, [item.dgi_style_sku]);

  const validItemInventory = Object.fromEntries(
    Object.entries(itemInventory ?? {}).filter(([_, productData]) => {
      const isLoading = productData?.loading === LOADING_STATES.LOADING_STATUS;
      const isAvailable =
        productData?.loading === LOADING_STATES.SUCCESS_STATUS &&
        productData?.data &&
        Object.keys(productData.data).length > 0 &&
        Boolean(productData.data.inventory_data);
      return (
        productData?.loading !== LOADING_STATES.FAILURE_STATUS &&
        (isLoading || isAvailable)
      );
    })
  );

  const validVendorCodes = Object.keys(validItemInventory)
    .map((key) =>
      key.slice(item.dgi_style_sku.length, -item.master_color.length)
    )
    .filter((code, index, self) => self.indexOf(code) === index);

  const getSSProductUrlShort = (vendorCode) => {
    if (vendorCode === "ss") {
      const dataKey = item.dgi_style_sku + "ss" + item.master_color;
      const productUrl = validItemInventory[dataKey]?.data?.url;
      return productUrl ? new URL(productUrl).pathname : null;
    }
    return null;
  };

  const handleSwitchCart = async (
    selectedItemPrice,
    selectedVendorCode,
    destWarehouse
  ) => {
    const destWarehouseCode = destWarehouse?.[1]?.warehouseCode;
    const selectedSize = destWarehouse.selectedSize;

    const destItem = {
      ...destWarehouse[1]?.quantities.find((q) => q.sizeLabel === selectedSize),
      quantity: item.quantity,
    };

    const ssProductUrl = getSSProductUrlShort(selectedVendorCode);
    if (ssProductUrl) {
      destItem.ss_product_url_short = ssProductUrl;
    }

    const { source_result, dest_result } = await switchCart(
      sourceVendorCode,
      [item],
      item.warehouseCode,
      selectedVendorCode,
      [destItem],
      destWarehouseCode,
      selectedItemPrice,
      userCreds?.cognitoID
    );

    dispatch(
      fetchCartSuccess({
        vendorCode: sourceVendorCode,
        cartData: source_result.cartData,
      })
    );

    if (selectedVendorCode !== sourceVendorCode) {
      dispatch(
        fetchCartSuccess({
          vendorCode: selectedVendorCode,
          cartData: dest_result.cartData,
        })
      );
    }

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <VendorOptionsWrapper
        validVendorCodes={validVendorCodes}
        validItemInventory={validItemInventory}
        item={item}
        onSwitchCart={handleSwitchCart}
      />
    </Dialog>
  );
}

function SwitchCartWrapper({ open, handleClose, item, sourceVendorCode }) {
  const { userCreds } = useUserStateContext();
  const dispatch = useDispatch();
  const styleData = useSelector((state) =>
    selectStyle(state, item?.dgi_style_sku)
  );

  useEffect(() => {
    const fetchStyleData = async () => {
      if (!styleData) {
        const headers = { "User-Identifier": userCreds?.cognitoID };
        const productResp = await lilProductClient.get(
          endpoints.getProductInfo(item?.dgi_style_sku),
          { headers }
        );
        dispatch(
          fetchStyleSuccess({
            styleObj: processProductData(productResp?.data),
          })
        );
      }
    };
    fetchStyleData();
  }, [item.dgi_style_sku, styleData]);

  if (!styleData) {
    return (
      <Backdrop
        sx={{
          backgroundColor: "rgba(88, 88, 88, 0.2)",
          zIndex: (theme) => theme.zIndex.modal,
        }}
        open={!styleData}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <SwitchCartDialog
      open={open}
      handleClose={handleClose}
      item={item}
      sourceVendorCode={sourceVendorCode}
      styleData={styleData}
    />
  );
}

function SwitchCartInterface({ item, sourceVendorCode }) {
  const [switchCartDialogOpen, setSwitchCartDialogOpen] = useState(false);

  return (
    <div className="flex items-center max-w-fit">
      <Button
        className="normal-case text-typeGrey rounded-[5px] p-[8px] flex items-center"
        disableRipple
        sx={{
          transition: "all 0.2s ease",
          "&:hover": {
            backgroundColor: "#F8F8F8",
            padding: "10px",
          },
          "&:hover span, svg": {
            color: "rgba(0, 110, 165)",
          },
        }}
        onClick={() => setSwitchCartDialogOpen(true)}
      >
        <span className="flex items-center text-typeGrey text-almostSM">
          Switch Cart <Shuffle size={15} className="ml-[6px]" />
        </span>
      </Button>

      {switchCartDialogOpen && (
        <SwitchCartWrapper
          open={switchCartDialogOpen}
          handleClose={() => setSwitchCartDialogOpen(false)}
          item={item}
          sourceVendorCode={sourceVendorCode}
        />
      )}
    </div>
  );
}

export default SwitchCartInterface;
