import React, { useState, useEffect } from "react";

import styles from "./styles.module.scss";
import Dialog from "@mui/material/Dialog";
import ColorSelectionButton from "./ColorSelectionButton";
import { usePricesContext } from "./Product";
import { ColorInfoText } from "custom/text/shopping_page/StandardProductCardTexts";
import { useUserStateContext } from "App";
import { useDataContext } from "App";
import { useDispatch } from "react-redux";
import { Typography, TextField } from "@mui/material";
import { ProductInfoText } from "custom/text/shopping_page/StandardProductCardTexts";
import SearchIcon from "@mui/icons-material/Search";
import {
  getConnectedVendors,
  getExistingProductKeys,
  createSocketPayload,
  sendPayload,
} from "features/useSocketPayloadSender";
import { fuzzyMatch } from "utilities/fuzzyMatch";

export default function ColorSelection({
  hit,
  colorsData,
  sendJsonMessage,
  displayStyleNumber,
}) {
  const [open, setOpen] = useState(false);
  const dgiStyle = hit.id;
  const matchedColorsIndexes =
    hit?._highlightResult?.colors?.reduce((indexes, color, index) => {
      if (color.name.matchLevel === "full") {
        indexes.push(index);
      }
      return indexes;
    }, []) || [];
  const matchedColors = matchedColorsIndexes.map((index) => colorsData[index]);
  const unmatchedColors = colorsData.filter(
    (color) => !matchedColorsIndexes.includes(colorsData.indexOf(color))
  );
  const newColorsArray = [...matchedColors, ...unmatchedColors];
  const [colorsArray, setColorsArray] = useState(newColorsArray);
  const { selectedColor } = usePricesContext();
  const { userCreds } = useUserStateContext();
  const { connectedVendorCodes } = useDataContext();
  const dispatch = useDispatch();
  const [colorInSearchBox, setColorInSearchBox] = useState("");
  const [filteredColors, setFilteredColors] = useState(newColorsArray);

  useEffect(() => {
    if (selectedColor.name) {
      const colorIndex = colorsData.findIndex((color) => {
        return color.name.toLowerCase() === selectedColor.name.toLowerCase();
      });

      const otherColors = colorsData
        .slice(0, colorIndex)
        .concat(colorsData.slice(colorIndex + 1));
      const matchedColors = matchedColorsIndexes
        .filter((index) => index !== colorIndex)
        .map((index) => colorsData[index]);
      const unmatchedColors = otherColors.filter(
        (color) => !matchedColorsIndexes.includes(colorsData.indexOf(color))
      );
      const newColorsArray = [
        selectedColor,
        ...matchedColors,
        ...unmatchedColors,
      ];
      setColorsArray(newColorsArray);
    }
  }, [selectedColor, colorsData]);

  const resetSelectionDialog = () => {
    setColorInSearchBox("");
    setFilteredColors(newColorsArray);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
    resetSelectionDialog();
    return;
  };

  const sendColorMessage = (masterColor) => {
    const getMasterColor = (hit) => {
      return hit["colors"].find((color) => color?.name === masterColor);
    };
    const userId = userCreds?.cognitoID;
    const connectedVendors = getConnectedVendors(connectedVendorCodes);
    const productKeys = [
      {
        dgi_style_sku: hit.id,
        master_color: masterColor,
        direct_vendor: hit.direct_vendor,
      },
    ];
    const existingProductKeys = getExistingProductKeys(
      dispatch,
      productKeys,
      connectedVendors
    );
    const payload = createSocketPayload(
      dispatch,
      userId,
      existingProductKeys,
      [hit],
      connectedVendors,
      getMasterColor
    );
    sendPayload(sendJsonMessage, payload);
  };

  const shortColors = colorsArray.slice(0, 3);

  const renderSelectedColor = colorsArray.length > 0 && (
    <div className="cursor-pointer">
      {selectedColor ? (
        <ColorInfoText
          content="Color: "
          selectedColor={`${selectedColor.name}`}
        />
      ) : (
        <ColorInfoText content="Choose a color" selectedColor={null} />
      )}
    </div>
  );

  const renderMoreColorsPrompt = colorsArray.length > 0 && (
    <div
      className={styles.moreColorsPrompt}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(true);
      }}
    >
      <ColorInfoText content="More colors..." />
    </div>
  );

  const renderNoColorsMessage = filteredColors.length === 0 && (
    <div
      className={`
        flex justify-start items-center 
        w-full h-full ml-[10px] mt-[10px]`}
    >
      <Typography className="text-base">
        No matching colors. Try searching for another color.
      </Typography>
    </div>
  );

  const handleColorSearchChange = (e) => {
    const colorSearch = e.target.value;
    setFilteredColors(
      newColorsArray
        ?.filter(color => !colorSearch || fuzzyMatch(color.name, colorSearch).score > 0)
        .sort((a, b) => {
          const scoreA = fuzzyMatch(a.name, colorSearch);
          const scoreB = fuzzyMatch(b.name, colorSearch);
          return scoreB.score - scoreA.score;
        })
    );
    setColorInSearchBox(colorSearch);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {!open && (
        <div className={styles.colorSelectionWrapper}>
          {renderSelectedColor}
          <div className={styles.shortColorSelection}>
            <div className={styles.colorSwatches}>
              {shortColors.map((c, index) => (
                <ColorSelectionButton
                  id={`${dgiStyle}-${c.name}-select-btn`}
                  key={`${dgiStyle}-${c.name}-select-btn`}
                  dgiStyle={dgiStyle}
                  color={c}
                  setOpen={setOpen}
                  inColorDialog={false}
                  hasSwatchText={false}
                  isFirstColor={index === 0}
                  sendColorMessage={sendColorMessage}
                  colorInSearchBox={colorInSearchBox}
                />
              ))}
            </div>
            {renderMoreColorsPrompt}
          </div>
        </div>
      )}
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: {
            maxWidth: "none",
            minHeight: "175px",
          },
        }}
      >
        <div className="ml-[10px] mt-[10px] text-lg">
          <ProductInfoText
            emboldenedContent={`${displayStyleNumber} • `}
            content={"Colors"}
          />
        </div>
        <TextField
          autoComplete="off"
          autoFocus
          placeholder="Search by color name..."
          value={colorInSearchBox}
          onChange={handleColorSearchChange}
          className="mx-[8px] my-[10px]"
          sx={{
            "& .MuiOutlinedInput-root": {
              boxShadow: "inset 0px -2px 4px rgba(0, 0, 0, 0.1)", // give bottom of textfield some depth
            },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: "rgb(2 129 192)", // lighterblueblue
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgb(2 129 192)", // lighterblueblue
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <SearchIcon className="text-grayscaleSmoke mr-[6px]" />
            ),
          }}
        />
        <div className={styles.colorSelection}>
          {filteredColors.map((c, index) => (
            <ColorSelectionButton
              id={`${dgiStyle}-${c.name}-select-btn`}
              key={`${dgiStyle}-${c.name}-select-btn`}
              dgiStyle={dgiStyle}
              color={c}
              setOpen={setOpen}
              inColorDialog={true}
              sendColorMessage={sendColorMessage}
              colorInSearchBox={colorInSearchBox}
              resetSelectionDialog={resetSelectionDialog}
            />
          ))}
          {renderNoColorsMessage}
        </div>
      </Dialog>
    </div>
  );
}
