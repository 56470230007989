import React, { useState } from "react";
import { Button, CircularProgress, Skeleton } from "@mui/material";

function WarehousesLoading() {
  return (
    <div className="grow flex flex-col w-2/3">
      {[...Array(3)].map((_, index) => (
        <div
          key={index}
          className={`
                flex items-center space-x-4 px-[12px] w-full
                ${index !== 2 ? "border-b border-[#d4d4d4]" : ""}
              `}
        >
          <div className="flex-1">
            <Skeleton variant="text" width="100%" height={65} />
          </div>
        </div>
      ))}
      <div className="flex justify-center">
        <Skeleton variant="text" width="50%" height={65} />
      </div>
    </div>
  );
}

function WarehouseOption({
  warehouseName,
  warehouseDetails,
  selectedSize,
  requiredQuantity,
  isSelected,
  onSelect,
  isLastOption,
  isDisabled,
}) {
  const sizeQuantity = warehouseDetails.quantities.find(
    (q) => q.sizeLabel === selectedSize
  )?.stockNum;

  const hasEnoughStock = React.useMemo(() => {
    if (!sizeQuantity) return false;

    const hasSpecialChars =
      typeof sizeQuantity === "string" &&
      (sizeQuantity.includes("+") ||
        sizeQuantity.includes(">") ||
        sizeQuantity.includes("<"));

    return (
      hasSpecialChars ||
      (Number(sizeQuantity) > 0 && Number(sizeQuantity) >= requiredQuantity)
    );
  }, [sizeQuantity, requiredQuantity]);

  return (
    <div
      className={`
        flex items-center space-x-4 px-[12px] py-[16px]
        w-full text-almostSM
        ${isSelected ? "bg-dgiWhite" : "hover:bg-lightestGrey"}
        ${
          hasEnoughStock && !isDisabled
            ? "cursor-pointer text-wolfGrey"
            : "bg-transparent hover:bg-transparent pointer-events-none text-grayscaleSmoke"
        }
        ${!isLastOption ? "border-b border-[#d4d4d4]" : ""}
      `}
      onClick={() => hasEnoughStock && !isDisabled && onSelect(warehouseName)}
    >
      <div className="whseName flex-1 font-semibold">{warehouseName}</div>
      <div className="whseSizeQuantity flex-1">{sizeQuantity} in Stock</div>
      <div className="whseEstDeliveryDate flex-1">
        Est Delivery: {warehouseDetails.estDeliveryDate}
      </div>
      <input
        type="radio"
        checked={isSelected}
        onChange={() =>
          hasEnoughStock && !isDisabled && onSelect(warehouseName)
        }
        value={warehouseName}
        name="warehouse-radio"
        className="accent-[rgb(2_129_192)] w-4 h-4"
        disabled={!hasEnoughStock || isDisabled}
      />
    </div>
  );
}

function VendorWarehouses({
  warehouseData,
  selectedSize,
  selectedVendorCode,
  item,
  selectedPrice,
  selectedWarehouse,
  onWarehouseSelect,
  onSwitchCart,
}) {
  const [isSwitching, setIsSwitching] = useState(false);

  const sortedWarehouses = React.useMemo(() => {
    if (!warehouseData) return [];

    return [...warehouseData].sort((a, b) => {
      const getDateTime = (warehouse) => {
        const date = warehouse[1]?.estDeliveryDate;
        if (!date) return Infinity;

        const mmddString =
          selectedVendorCode === "ss" ? date.split(" ")[1] : date;

        if (!/^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])$/.test(mmddString)) {
          return Infinity;
        }

        const [month, day] = mmddString
          .split("/")
          .map((unit) => unit.padStart(2, "0"))
          .map(Number);

        const currentYear = new Date().getFullYear();
        return new Date(currentYear, month - 1, day);
      };

      const dateA = getDateTime(a);
      const dateB = getDateTime(b);

      return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
    });
  }, [warehouseData, selectedVendorCode]);

  const handleSwitchCart = async () => {
    if (!selectedWarehouse || isSwitching) return;

    setIsSwitching(true);
    await onSwitchCart();
  };

  if (sortedWarehouses.length === 0) {
    return <WarehousesLoading />;
  }

  return (
    <div className="grow flex flex-col w-2/3">
      {sortedWarehouses.map(([name, details], index) => (
        <WarehouseOption
          key={name}
          warehouseName={name}
          warehouseDetails={details}
          selectedSize={selectedSize}
          requiredQuantity={item.quantity}
          isSelected={selectedWarehouse === name}
          onSelect={onWarehouseSelect}
          isLastOption={index === sortedWarehouses.length - 1}
          isDisabled={isSwitching}
        />
      ))}

      <div className="flex justify-center">
        <Button
          onClick={handleSwitchCart}
          disabled={!selectedWarehouse || isSwitching}
          className={`
            ${
              selectedWarehouse && !isSwitching
                ? "bg-lighterblueblue hover:bg-blueblue"
                : "bg-grayscaleSilver"
            }
            text-dgiWhite mt-[20px] max-w-[250px] w-full
          `}
        >
          {isSwitching ? (
            <CircularProgress size={24} className="text-dgiWhite" />
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </div>
  );
}

export default VendorWarehouses;
